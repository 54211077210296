import React from 'react';
import propTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { dimensions as widgetDimensions } from '../widget';
import Widget from '../widget/Widget';
import UpdatingData from '../updatingData/UpdatingData';
import ScaleComponent from './components/scaleComponent/ScaleComponent';
import aqiDefinitions from './aqiDefinitions';
import styles from './styles';

const AirQualityBox = ({ title, value, size = 'huge', noData }) => {
    const classes = styles();
    const widgetSize = widgetDimensions[size];
    const isMobile = window.innerWidth < 708;
    const height = isMobile ? "80px" : "70px";

    return (
        <Widget
            title={<FormattedMessage id={title} />}
            {...widgetSize}
            titleVariant="h5"
        >
            <div style={{height}}>
                <div className={classes.scaleComponent}>
                    {aqiDefinitions.map((aqiGrade, index) => {
                        return (
                            <ScaleComponent
                                isActive={aqiGrade.value === value}
                                color={aqiGrade.color}
                                gradeAsText={aqiGrade.translationKey}
                                key={index}
                                index={index}
                            />
                        );
                    })}
                </div>
            </div>
            {noData && <UpdatingData />}
        </Widget>
    );
};

AirQualityBox.propTypes = {
    title: propTypes.string,
    value: propTypes.oneOfType([propTypes.number, propTypes.string]),
    size: propTypes.string,
    noData: propTypes.bool,
};

export default AirQualityBox;
