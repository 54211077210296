export { default } from './Widget';

export const dimensions = {
    small: {
        lg: 3,
        md: 3,
        sm: 6,
        xs: 12,
    },
    medium: {
        lg: 6,
        md: 6,
        sm: 6,
        xs: 12,
    },
    large: {
        lg: 8,
        md: 6,
        sm: 12,
        xs: 12,
    },
    huge: {
        lg: 12,
        md: 12,
        sm: 12,
        xs: 12,
    },
};
