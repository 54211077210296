import React from 'react';
import { Grid } from '@material-ui/core';
import propTypes from 'prop-types';

import { FormattedMessage } from 'react-intl';
import scaleBoxTypes from '../../components/scaleBox/scaleBoxTypes';
import { Chart, Widget, AirQualityBox, ScaleBox, Map } from '../../components';
import { dimensions } from '../../components/widget';

import withSensorData from '../../hocs/withSensorData';

import chartConfig from './chartConfig';

const WistaPage = ({ sensorData, error }) => {
    const coloredBarChartConfig = chartConfig(sensorData, error);

    return (
        <Grid container spacing={3}>
            <Grid container item spacing={3} xs={12}>
                <AirQualityBox
                    title="sensors.airQualityBox.aqi.title"
                    value={sensorData?.aqi?.airQualityIndex}
                    noData={error}
                />
            </Grid>
            <Grid item container xs={12} md={6} spacing={3}>
                <ScaleBox
                    value={sensorData?.aqi?.ozone}
                    unit="µg/m3"
                    title="sensors.widgets.o3_average.title"
                    scaleBoxType={scaleBoxTypes.OZONE}
                    size="medium"
                    noData={error}
                />
                <ScaleBox
                    value={sensorData?.aqi?.coarseParticulateMatter}
                    unit="µg/m3"
                    title="sensors.widgets.pm10_average.title"
                    scaleBoxType={scaleBoxTypes.PARTICULATE_MATTER}
                    size="medium"
                    noData={error}
                />
                <ScaleBox
                    value={sensorData?.aqi?.nitrogenDioxide}
                    unit="µg/m3"
                    title="sensors.widgets.nox_average.title"
                    scaleBoxType={scaleBoxTypes.NITROGEN_DIOXIDE}
                    size="medium"
                    noData={error}
                />
                <ScaleBox
                    value={sensorData?.aqi?.carbonMonoxide}
                    unit="mg/m3"
                    title="sensors.widgets.co_average.title"
                    scaleBoxType={scaleBoxTypes.CARBON_MONOXIDE}
                    size="medium"
                    noData={error}
                />
            </Grid>
            <Grid item xs={12} md={6} container>
                <Map
                    title="map.title"
                    data={
                        (sensorData?.location && [sensorData?.location]) || []
                    }
                    size="huge"
                    disablePopup
                    height="400px"
                    noData={error}
                />
            </Grid>
            <Grid item xs={12} container spacing={3}>
                <Widget
                    title={
                        <FormattedMessage id="sensors.coloredLineChart.title" />
                    }
                    {...dimensions.huge}
                    titleVariant="h5"
                >
                    <Chart {...coloredBarChartConfig.aqiConfig} />
                    <Chart {...coloredBarChartConfig.pm10Config} />
                    <Chart {...coloredBarChartConfig.no2Config} />
                    <Chart {...coloredBarChartConfig.ozoneConfig} />
                    <Chart {...coloredBarChartConfig.coConfig} />
                </Widget>
            </Grid>
        </Grid>
    );
};

WistaPage.propTypes = {
    sensorData: propTypes.object,
    error: propTypes.bool,
};

export default withSensorData(WistaPage);
