export default (seriesLength, wistaBlue, xAxisAnnotations, dashSize = 0, grade) => ({
    stroke: {
        width: 3,
        dashArray: dashSize,
    },
    xaxis: {
        axisBorder: {
            show: false,
        },
        axisTicks: {
            show: false,
        },
        type: 'numeric',
        tickAmount: seriesLength,
        position: 'top',
        hideOverlappingLabels: false,
        labels: {
            rotate: 0,
            formatter: () => {
                return '';
            },
        },
    },
    yaxis: {
        min: 0,
        max: 60,
        tickAmount: 6,
        axisBorder: {
            show: true,
            color: '#FFF',
            offsetX: -2,
        },
        labels: {
            offsetY: -30,
            minWidth: 100,
            align: 'left',
            style: {
                colors: [
                    '#32A6CB',
                    '#32A6CB',
                    '#32A6CB',
                    '#FFF',
                    '#FFF',
                    '#FFF',
                ],
                fontWeight: 900,
            },
            formatter: (val, index) => {
                switch (index) {
                    case 0: return grade[index];
                    case 1: return grade[index];
                    case 2: return grade[index];
                    case 3: return grade[index];
                    case 4: return grade[index];
                    case 5: return grade[index];
                    default: return '';
                }
            },
        },
    },
    tooltip: {
        enabled: false,
    },
    grid: {
        xaxis: {
            lines: {
                show: false,
            },
        },
        yaxis: {
            lines: {
                show: false,
            },
        },
    },
    legend: {
        fontSize: '18rem',
        fontWeight: 900,
        labels: {
            useSeriesColors: true,
        },
        formatter: (seriesName, opt) => {
            return `${seriesName} <span class='legend-${opt.seriesIndex}' />`;
        },
        markers: {
            width: 0,
            height: 0,
        },
    },
    annotations: {
        position: 'back',
        xaxis: xAxisAnnotations,
    },
});
