const getPlotsFromSensorType = (sensorData, type) => {
    if (!sensorData.plots || !sensorData.plots[type]) return [];
    return sensorData.plots[type];
};

export default (sensorData, error) => ({
    aqiConfig: {
        plots: [{ data: getPlotsFromSensorType(sensorData, 'AQI') }],
        isLabled: true,
        isColored: true,
        unit: 'LQI',
        type: 'coloredBar',
        height: 150,
        noData: error,
    },
    pm10Config: {
        plots: [{ data: getPlotsFromSensorType(sensorData, 'PARTICULATE_MATTER') }],
        isLabled: false,
        isColored: false,
        unit: 'PM10',
        type: 'coloredBar',
        height: 150,
        noData: error,
    },
    no2Config: {
        plots: [{ data: getPlotsFromSensorType(sensorData, 'NITROGEN_DIOXIDE') }],
        isLabled: false,
        isColored: false,
        unit: 'NOX',
        type: 'coloredBar',
        height: 150,
        noData: error,
    },
    ozoneConfig: {
        plots: [{ data: getPlotsFromSensorType(sensorData, 'OZONE') }],
        isLabled: false,
        isColored: false,
        unit: 'O3',
        type: 'coloredBar',
        height: 150,
        noData: error,
    },
    coConfig: {
        plots: [{ data: getPlotsFromSensorType(sensorData, 'CARBON_MONOXIDE') }],
        isLabled: false,
        isColored: false,
        unit: 'CO',
        type: 'coloredBar',
        height: 150,
        noData: error,
    },
});
