import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(theme => ({
    outer: {
        height: '500px',
        position: 'relative',
        paddingBottom: 0,
        '& *': {
            fontFamily: '"DinWeb"',
        },
    },
    notMobile: {
        minWidth: 1160,
    },
    errorMessage: {
        textAlign: 'center',
        marginTop: '20px',
    },
    coloredBar: {
        '& .apexcharts-yaxis-annotations line': {
            transform: 'scaleX(1.2)',
        },
        '& .hide-labels': {
            display: 'none',
        },
    },
    coloredYAxis: {
        height: '100%',
        '& .apexcharts-canvas': {
            backgroundImage:
                'linear-gradient(' +
                '#EC4452 13.2%, #F0655B 13.2%, ' +
                '#F0655B 26.4%, #F2A772 26.4%, ' +
                '#F2A772 39.5%, #FCEC6A 39.5%, ' +
                '#FCEC6A 52.6%, #A7F890 52.6%, ' +
                '#A7F890 65.6%, #50FFD0 65.6%, ' +
                '#50FFD0 79%, #FFF 79%, #FFF 100%)',
            backgroundPosition: '0 35px !important',
        },
    },
    legend: {
        '& .legend-1, .legend-2, .legend-0, .legend-3': {
            width: 60,
            display: 'inline-block',
            verticalAlign: 'middle',
        },
        '& .legend-0': {
            border: `2px solid ${theme.palette.wistaBlue}`,
        },
        '& .legend-1': {
            border: `2px dashed ${theme.palette.wistaBlue}`,
        },
        '& .legend-2': {
            border: `2px dotted ${theme.palette.wistaBlue}`,
        },
        '& .legend-3': {
            height: 4,
            backgroundImage: 'linear-gradient(to right,' +
                '#32A6CB 20%, #FFF 20%, ' +
                '#FFF 40%, #32A6CB 40%, ' +
                '#32A6CB 60%, #FFF 60%, ' +
                '#FFF 80%, #32A6CB 80%, ' +
                '#32A6CB 100%)',
        },
    },
}));

export default styles;
