import React from 'react';
import { MapContainer, TileLayer, useMap, Polygon } from 'react-leaflet';
import propTypes from 'prop-types';
import 'leaflet/dist/leaflet.css';
import { useTheme } from '@material-ui/core/styles';

import { FormattedMessage } from 'react-intl';
import CustomMarker from './CustomMarker';
import { dimensions } from '../widget';
import Widget from '../widget/Widget';
import UpdatingData from '../updatingData/UpdatingData';

// component is only needed to receive actual map ref and to use leaflet methods
const MapRef = ({ clickedMarker }) => {
    const map = useMap();
    let marker;
    map.eachLayer(layer => {
        if (layer.options && layer.options.id === clickedMarker) marker = layer;
    });

    if (marker && clickedMarker) {
        marker.openPopup();
    }

    return null;
};

const LeafletMap = ({
    data,
    height,
    clickedMarker,
    polygon = [],
    disablePopup = false,
    title,
    size = 'medium',
    noData,
}) => {
    const isPolygon = polygon.length >= 3;
    const theme = useTheme();
    const widgetSize = dimensions[size];
    const MOCK_BOUNDS = [
        [52.4989896, 13.5043423],
        [55.2524231, 37.6402272],
    ]; // if no data, set at least fake bounds to display the map properly (52.4989896,13.5043423 => berlin, 55.2524231, 37.6402272 => moskau)

    const getMarker = () => {
        return data.map(marker => ({
            name: marker.name,
            position: [marker.latitude, marker.longitude],
        }));
    };

    const getCenterPosition = coordinates => {
        const centerLat =
            coordinates.reduce((acc, current) => {
                return acc + current.latitude;
            }, 0) / coordinates.length;

        const centerLong =
            coordinates.reduce((acc, current) => {
                return acc + current.longitude;
            }, 0) / coordinates.length;

        return [centerLat, centerLong];
    };

    // if position of polygon is known, use it to set bounds on the map, otherwise use marker contained by the data property
    const getSortedCoordinatePositions = coordinates => {
        const center = getCenterPosition(coordinates);
        const sortedCoordinates = coordinates
            .map(pos => ({
                ...pos,
                angle: Math.atan2(
                    pos.latitude - center[0],
                    pos.longitude - center[1],
                ),
            }));

        if (!sortedCoordinates.length) return null;

        return sortedCoordinates.map(marker => [
            marker.latitude,
            marker.longitude,
        ]);
    };

    return (
        <Widget
            title={<FormattedMessage id={title} />}
            {...widgetSize}
            titleVariant="h5"
        >
            <MapContainer
                bounds={
                    getSortedCoordinatePositions(isPolygon ? polygon : data) ||
                    MOCK_BOUNDS
                }
                style={{ minHeight: height }}
                preferCanvas
            >
                <MapRef clickedMarker={clickedMarker} />
                <TileLayer
                    attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <CustomMarker data={getMarker()} disablePopup={disablePopup} />
                {isPolygon && (
                    <Polygon
                        positions={getSortedCoordinatePositions(polygon)}
                        pathOptions={{ color: theme.palette.primary.main }}
                    />
                )}
            </MapContainer>

            {noData && <UpdatingData />}
        </Widget>
    );
};

LeafletMap.propTypes = {
    data: propTypes.any,
    height: propTypes.string,
    disablePopup: propTypes.bool,
    noData: propTypes.bool,
    clickedMarker: propTypes.string,
    polygon: propTypes.array,
    title: propTypes.string.isRequired,
    size: propTypes.string,
};

export default LeafletMap;
