import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
    appBar: {
        background: `linear-gradient(to right, ${theme.palette.wistaBlue}, ${theme.palette.wistaGreen})`,
        marginBottom: '1.6rem',
        width: '100%',
    },
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingLeft: 0,
        paddingRight: 0,
    },
}));
