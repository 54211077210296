import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
    root: {
        color: '#ffffff',
        position: 'relative',
        display: 'flex',
        padding: 4,
        alignItems: 'center',
    },
    currentAqiText: {
        position: 'relative',
        '&::after': {
            content: '""',
            position: 'absolute',
            top: 0,
            right: -8,
            width: 2,
            height: '100%',
            backgroundColor: '#ffffff',
        },
    },
    value: {
        marginLeft: 14,
        fontSize: 20,
    },
});
