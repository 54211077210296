import React from 'react';
import propTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';

import { REACT_APP_MOCKS } from '../../resources/env';
import styles from './styles';
import { getChartSettings } from './settings';
import * as ChartTypes from './chartTypes';
import UpdatingData from '../updatingData/UpdatingData';

function Chart({
    plots,
    type = 'line',
    withGradient = true,
    additionalSettings = {},
    unit,
    isColored = true,
    isLabeled = true,
    height = '500px',
    notMobile = false,
    noData,
}) {
    const classes = styles();

    // chart settings
    const theme = useTheme();
    const now = REACT_APP_MOCKS ? 1610106350240 : new Date().getTime();
    const chartSettings = getChartSettings(
        additionalSettings,
        theme,
        withGradient,
        now,
        plots,
        unit,
    );
    const ChartType = ChartTypes[type];

    return (
        <div
            className={clsx(
                classes.outer,
                type === 'coloredBar' && classes.coloredBar,
                notMobile && classes.notMobile,
            )}
            style={{ height }}
        >
            {plots && (
                <ChartType
                    additionalSettings={additionalSettings}
                    settings={chartSettings}
                    plots={plots}
                    unit={unit}
                    isColored={isColored}
                    isLabeled={isLabeled}
                    height={height}
                />
            )}

            {noData && <UpdatingData />}
        </div>
    );
}

Chart.propTypes = {
    plots: propTypes.array.isRequired,
    type: propTypes.string,
    withGradient: propTypes.bool,
    additionalSettings: propTypes.object,
    unit: propTypes.string,
    size: propTypes.string,
    title: propTypes.string,
    isColored: propTypes.bool,
    isLabeled: propTypes.bool,
    height: propTypes.oneOfType([propTypes.number, propTypes.string]),
    xAxisPosition: propTypes.string,
    notMobile: propTypes.bool,
    noData: propTypes.bool,
};

export default Chart;
