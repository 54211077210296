import React from 'react';
import { withStyles, Typography, Paper, Box, Grid } from '@material-ui/core';
import propTypes from 'prop-types';

import styles from './styles';

function Widget({
    classes,
    children,
    title = null,
    titleVariant = 'caption',
    ...dimensions
}) {
    return (
        <Grid item {...dimensions}>
            <Paper
                className={classes.paper}
                classes={{ root: classes.widgetRoot }}
                aria-label="widgetPaper"
            >
                {title && (
                    <Typography
                        variant={titleVariant}
                        className={classes.widgetHeader}
                    >
                        {title}
                    </Typography>
                )}
                <Box className={classes.widgetBody} aria-label="widgetBody">
                    {children}
                </Box>
            </Paper>
        </Grid>
    );
}

Widget.propTypes = {
    classes: propTypes.object,
    title: propTypes.any,
    titleVariant: propTypes.string,
    children: propTypes.any,
};

export default withStyles(styles)(Widget);
