import React from 'react';
import propTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import scaleBoxTypes from '../../components/scaleBox/scaleBoxTypes';
import SensorData from '../../hocs/withSensorData';

import {
    AirQualityIndexSimple,
    AirQualityBox,
    ScaleBox,
    Map,
    Chart,
    Widget,
} from '../../components';

import { dimensions } from '../../components/widget';

import getColoredLineChartConfig from '../steles/chartConfig';
import getColoredBarChartConfig from '../wista-page/chartConfig';

const Widgets = ({ sensorData, error }) => {
    const query = new URLSearchParams(useLocation().search);
    const currentWidgetType = query.get('type');
    const coloredLineChartConfig = getColoredLineChartConfig(sensorData);
    const coloredBarChartConfig = getColoredBarChartConfig(sensorData, error);

    const availableWidgets = [
        {
            type: 'aqiSimple',
            component: AirQualityIndexSimple,
            props: {
                value: sensorData.aqi?.airQualityIndex,
                noData: error,
            },
        },
        {
            type: 'aqiScale',
            component: AirQualityBox,
            props: {
                title: 'sensors.airQualityBox.aqi.title',
                value: sensorData.aqi?.airQualityIndex,
                noData: error,
            },
        },
        {
            type: 'pollutantBoxes',
            markup: (
                <Grid container xs={12} spacing={3}>
                    <ScaleBox
                        value={sensorData?.aqi?.ozone}
                        unit="µg/m3"
                        title="1h-Mittel O3"
                        scaleBoxType={scaleBoxTypes.OZONE}
                        size="medium"
                        noData={error}
                    />
                    <ScaleBox
                        value={sensorData?.aqi?.coarseParticulateMatter}
                        unit="µg/m3"
                        title="24h-Mittel PM10"
                        scaleBoxType={scaleBoxTypes.PARTICULATE_MATTER}
                        size="medium"
                        noData={error}
                    />
                    <ScaleBox
                        value={sensorData?.aqi?.nitrogenDioxide}
                        unit="µg/m3"
                        title="1h-Mittel NO2"
                        scaleBoxType={scaleBoxTypes.NITROGEN_DIOXIDE}
                        size="medium"
                        noData={error}
                    />
                    <ScaleBox
                        value={sensorData?.aqi?.carbonMonoxide}
                        unit="µg/m3"
                        title="8h-Mittel CO"
                        scaleBoxType={scaleBoxTypes.CARBON_MONOXIDE}
                        size="medium"
                        noData={error}
                    />
                </Grid>
            ),
        },
        {
            type: 'map',
            component: Map,
            props: {
                title: 'map.title',
                data: (sensorData?.location && [sensorData?.location]) || [],
                size: 'huge',
                disablePopup: true,
                height: 300,
                noData: error,
            },
        },
        {
            type: 'aqiHistoryLineChart',
            markup: (
                <Widget
                    title={
                        <FormattedMessage id="sensors.coloredLineChart.title" />
                    }
                    {...dimensions.huge}
                    titleVariant="h5"
                >
                    <Chart
                        type="coloredLine"
                        withGradient={false}
                        notMobile
                        plots={coloredLineChartConfig}
                        noData={error}
                    />
                </Widget>
            ),
        },
        {
            type: 'aqiHistoryBarChart',
            markup: (
                <Widget
                    title={
                        <FormattedMessage id="sensors.coloredLineChart.title" />
                    }
                    {...dimensions.huge}
                    titleVariant="h5"
                >
                    <Chart {...coloredBarChartConfig.aqiConfig} />
                    <Chart {...coloredBarChartConfig.pm10Config} />
                    <Chart {...coloredBarChartConfig.no2Config} />
                    <Chart {...coloredBarChartConfig.ozoneConfig} />
                    <Chart {...coloredBarChartConfig.coConfig} />
                </Widget>
            ),
        },
    ];

    const getWidgets = widgetType => {
        if (widgetType) {
            const currentWidget = availableWidgets.find(
                widget => widget.type === widgetType,
            );
            if (currentWidget) {
                const { component: Component, props, markup } = currentWidget;

                if (markup) return markup;

                return <Component {...props} />;
            }
        }

        return availableWidgets.map(
            ({ type, component: Component, props, markup }) => {
                if (markup) return markup;
                return <Component key={type} {...props} />;
            },
        );
    };

    return getWidgets(currentWidgetType);
};

Widgets.propTypes = {
    sensorData: propTypes.object,
    error: propTypes.bool,
};

export default SensorData(Widgets);
