import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { Container, CssBaseline } from '@material-ui/core';

import theme from './theme';
import './theme/normalize.css';

import { Layout } from './components';
import WistaPage from './pages/wista-page';
import Steles from './pages/steles';
import Widgets from './pages/widgets';

function App() {
    return (
        <BrowserRouter>
            <MuiThemeProvider theme={theme}>
                <CssBaseline />
                <Switch>
                    <Route path="/widgets" exact component={Widgets} />
                    <Layout>
                        <Container>
                            <Route path="/steles" exact component={Steles} />
                            <Route path="/" exact component={WistaPage} />
                        </Container>
                    </Layout>
                </Switch>
            </MuiThemeProvider>
        </BrowserRouter>
    );
}

export default App;
