import React from 'react';
import propTypes from 'prop-types';
import { ArrowDropDown } from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';

import styles from './styles';

const ScaleComponent = ({
    isActive,
    color,
    gradeAsText,
    index
}) => {
    const classes = styles();
    const isMobile = window.innerWidth < 708;

    return (
        <div className={classes.root} style={{backgroundColor: color}}>
            {isActive && (
                <ArrowDropDown className={classes.valueMarker} aria-hidden={false}/>
            )}
            {isMobile && (index === 0 || index === 5) && (
                <p className={classes.text}><FormattedMessage id={gradeAsText} /></p>
            )}
            {!isMobile && (
                <p className={classes.text}><FormattedMessage id={gradeAsText} /></p>
            )}
        </div>
    );
};

ScaleComponent.propTypes = {
    isActive: propTypes.bool,
    color: propTypes.string,
    gradeAsText: propTypes.string,
    index: propTypes.number,
};

export default ScaleComponent;
