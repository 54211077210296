const getPlotsFromSensorType = (sensorData, type) => {
    if (!sensorData.plots || !sensorData.plots[type]) return [];
    return sensorData.plots[type];
};

export default sensorData => [
    {
        id: 'OZONE',
        highlighted: true,
        color: '#32A6CB',
        title: 'sensors.charts.o3.longTitle',
        dashSize: 0,
        data: getPlotsFromSensorType(sensorData, 'OZONE'),
    },
    {
        id: 'PARTICULATE_MATTER',
        highlighted: false,
        color: '#32A6CB',
        title: 'sensors.charts.pm10.longTitle',
        data: getPlotsFromSensorType(sensorData, 'PARTICULATE_MATTER'),
        dashSize: 10,
    },
    {
        id: 'NITROGEN_DIOXIDE',
        highlighted: false,
        color: '#32A6CB',
        title: 'sensors.charts.no2.longTitle',
        data: getPlotsFromSensorType(sensorData, 'NITROGEN_DIOXIDE'),
        dashSize: 3,
    },
    {
        id: 'CARBON_MONOXIDE',
        highlighted: false,
        color: '#32A6CB',
        title: 'sensors.charts.co.longTitle',
        data: getPlotsFromSensorType(sensorData, 'CARBON_MONOXIDE'),
        dashSize: 20,
    },
];
