/* eslint-disable no-throw-literal */
import { REACT_APP_UE_API_BACKEND } from '../../resources/env';
import { ueApiInstance as instance } from '../../config/axios/instances';
import { getEndpoint } from '../../helper';

const isErrorResponseCode = response =>
    response.status < 200 || response.status > 299;
const baseUrl = `${REACT_APP_UE_API_BACKEND}`;

/**
 * The service wrapper is designed to either return the response or an error object.
 * Every service method is wrapped in a try catch block, where a catch is only reached,
 * if response status is 500 or a general error occurred.
 * usage: const { error, response } = await service method
 * if (error) {handle error}
 * else {handle response}
 */
const ueApiService = {
    getAsset: async (assetId) => {
        const endPoint = getEndpoint(
            `${baseUrl}/public/assets/${assetId}`,
            '/asset.json',
        );

        try {
            const response = await instance.get(endPoint);

            let error;

            if (isErrorResponseCode(response)) {
                error = {
                    name: 'getAssetException',
                    message: `Error for endpoint: ${endPoint}`,
                    statusCode: response.status,
                };
            }

            return { response: response.data, error };
        } catch {
            return {
                error: {
                    name: 'getAssetException',
                    message: `Error for endpoint: ${endPoint}`,
                },
            };
        }
    },
    getAQI: async (assetId) => {
        const endPoint = getEndpoint(
            `${baseUrl}/public/indices/aqi/${assetId}`,
            '/aqi.json',
        );

        try {
            const response = await instance.get(endPoint);

            let error;

            if (isErrorResponseCode(response)) {
                error = {
                    name: 'getAQIException',
                    message: `Error for endpoint: ${endPoint}`,
                    statusCode: response.status,
                };
            }

            return { response: response.data, error };
        } catch {
            return {
                error: {
                    name: 'getAQIException',
                    message: `Error for endpoint: ${endPoint}`,
                },
            };
        }
    },
    getAqiSeries: async (assetId) => {
        const endPoint = getEndpoint(
            `${baseUrl}/public/indices/aqi?asset_id=${assetId}&resolution=HOURLY&aggregation=AVG`,
            '/chart.json',
        );

        try {
            const response = await instance.get(endPoint);

            let error;

            if (isErrorResponseCode(response)) {
                error = {
                    name: 'getAqiSeries',
                    message: `Error for endpoint: ${endPoint}`,
                    statusCode: response.status,
                };
            }

            return { response: response.data, error };
        } catch {
            return {
                error: {
                    name: 'getAqiSeries',
                    message: `Error for endpoint: ${endPoint}`,
                },
            };
        }
    }
};

export default ueApiService;
