export default theme => ({
    widgetRoot: {
        boxShadow: theme.customShadows.widget,
    },
    widgetHeader: {
        paddingTop: theme.spacing(1),
        padding: theme.spacing(3),
        paddingBottom: theme.spacing(1),
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        background: `linear-gradient(to right, ${theme.palette.wistaBlue}, ${theme.palette.wistaGreen})`,
        color: theme.palette.white,
    },
    widgetBody: {
        height: '100%',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(2),
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        overflow: 'hidden',
        position: 'relative',
        color: theme.palette.wistaBlue,
    },
    noPadding: {
        padding: 0,
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        overflow: 'hidden',
        height: '100%',
    },
    error: {
        borderColor: theme.palette.error.main,
    },
    valid: {
        borderColor: theme.palette.primary.main,
    },
});
