import React from 'react';
import { Marker, Popup } from 'react-leaflet';
import propTypes from 'prop-types';
import { LocationMarkerIcon } from './LocationMarkerIcon';

const CustomMarker = ({ data, disablePopup }) => {
    return data.map(marker => (
        <Marker
            key={marker.name}
            position={marker.position}
            icon={LocationMarkerIcon}
            id={marker.name}
        >
            {!disablePopup && (
                <Popup>
                    <div>{marker.name}</div>
                </Popup>
            )}
        </Marker>
    ));
};

CustomMarker.propTypes = {
    data: propTypes.array,
    disablePopup: propTypes.bool,
};

export default CustomMarker;
