import React from 'react';
import propTypes from 'prop-types';
import { ArrowDropUp, ArrowDropDown } from '@material-ui/icons';

import styles from './styles';
import scaleBoxDefinitions from './scaleDefinitions';

const Scale = ({ scaleBoxType, value }) => {
    const classes = styles();
    const definition = scaleBoxDefinitions.find(
        scaleBoxDefinition => scaleBoxDefinition.type === scaleBoxType,
    );
    let valueInPercentage =
        ((value - definition.minValue) /
            (definition.maxValue - definition.minValue)) *
        92; // 92 instead of 100 for ui purposes

    if (valueInPercentage > 92) {
        valueInPercentage = 92;
    }

    return (
        <div className={classes.root}>
            <ArrowDropDown
                className={`${classes.valueMarker} ${classes.valueMarkerTop}`}
                style={{ left: `${valueInPercentage}%` }}
            />
            <ArrowDropUp
                className={`${classes.valueMarker} ${classes.valueMarkerBottom}`}
                style={{ left: `${valueInPercentage}%` }}
            />
            <span className={classes.scale} />
        </div>
    );
};

Scale.propTypes = {
    scaleBoxType: propTypes.string,
    value: propTypes.number,
    stringGrade: propTypes.string,
};

export default Scale;
