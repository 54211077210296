import React from 'react';
import propTypes from 'prop-types';
import { AppBar, Toolbar, Container, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom'

import styles from './styles';

const Layout = ({ children }) => {
    const location = useLocation();
    const classes = styles();
    return (
        <>
            <AppBar position="static" className={classes.appBar}>
                <Container>
                    {location.pathname !== "/" && <Toolbar className={classes.toolbar}>
                        <Typography variant="h4">WISTA</Typography>
                        <Typography variant="h5">
                            <FormattedMessage id="wista.header.title"/>
                        </Typography>
                    </Toolbar>}
                </Container>
            </AppBar>
            {children}
        </>
    );
};

Layout.propTypes = {
    children: propTypes.element,
};

export default Layout;
