export const REACT_APP_ENV = process.env.REACT_APP_ENV || 'local';
export const REACT_APP_HOST_NAME =
    process.env.REACT_APP_HOST_NAME || 'localhost:3000';
export const REACT_APP_COM2M_HOST_NAME =
    process.env.REACT_APP_COM2M_HOST_NAME || 'localhost';
export const REACT_APP_COM2M_PROTOCOL =
    process.env.REACT_APP_COM2M_PROTOCOL || 'http';
export const REACT_APP_COMPANY_TITLE =
    process.env.REACT_APP_COMPANY_TITLE || 'urban energy';
export const REACT_APP_AUTHORIZE_URL =
    process.env.REACT_APP_AUTHORIZE_URL ||
    'http://localhost:8090/oauth/authorize';
export const REACT_APP_TOKEN_URL =
    process.env.REACT_APP_TOKEN_URL || 'http://localhost:8090/oauth/token';
export const REACT_APP_CLIENT_SECRET =
    process.env.REACT_APP_CLIENT_SECRET ||
    'HRa0MqiRXK-2817-449c-8de4-MbPcLFAq2k';
export const REACT_APP_REDIRECT_URI =
    process.env.REACT_APP_REDIRECT_URI ||
    'http://localhost:3000/login-receiver';
export const REACT_APP_UE_API_BACKEND =
    process.env.REACT_APP_UE_API_BACKEND || 'http://localhost:9797';
export const REACT_APP_MOCKS = process.env.REACT_APP_MOCKS || false;
export const REACT_APP_COM2M_ENVIRONMENTAL_SENSOR =
    process.env.REACT_APP_COM2M_ENVIRONMENTAL_SENSOR ||
    '54e7ec36-4cbb-45f7-9efa-ffd2b945b5f6';
