import { createMuiTheme } from '@material-ui/core/styles';
import tinycolor from 'tinycolor2';
import DinWeb from './font/DINWeb.woff';
import DinWebBold from './font/DINWeb-Bold.woff';

const lightenRate = 7.5;
const darkenRate = 15;
const primaryFallback = '#4784A0';
const secondaryFallback = '#ED7680';

const dinWebFont = {
    fontFamily: 'DinWeb',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    src: `url(${DinWeb}) format('woff')`,
};

const dinBoldWebFont = {
    fontFamily: 'DinWeb',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 'bold',
    src: `url(${DinWebBold}) format('woff')`,
};

const getTheme = createMuiTheme({
    spacing: 8,
    palette: {
        primary: {
            main: primaryFallback,
            light: tinycolor(primaryFallback)
                .lighten(lightenRate)
                .toHexString(),
            dark: tinycolor(primaryFallback)
                .darken(darkenRate)
                .toHexString(),
            contrastText: '#FFFFFF',
        },
        secondary: {
            main: secondaryFallback,
            light: tinycolor(secondaryFallback)
                .lighten(lightenRate)
                .toHexString(),
            dark: tinycolor(secondaryFallback)
                .darken(darkenRate)
                .toHexString(),
            contrastText: '#FFFFFF',
        },
        text: {
            primary: '#000000',
            secondary: '#6E6E6E',
            hint: '#B9B9B9',
        },
        background: {
            default: '#E7E7E8',
            light: '#E7E7E8',
        },
        error: {
            main: '#ff4b4b',
        },
        chartCrosshair: '#ff5959',
        chartForecastGradient: '#c2c2c2',
        white: '#FFFFFF',
        black: '#000000',
        wistaBlue: '#32A6CB',
        wistaGreen: '#92C65F',
    },
    customShadows: {
        widget:
            '0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
        widgetDark:
            '0px 3px 18px 0px #4558A3B3, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
        widgetWide:
            '0px 12px 33px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
    },
    overrides: {
        MuiPaper: {
            rounded: {
                borderRadius: 8,
            },
        },
        MuiCssBaseline: {
            '@global': {
                '@font-face': [dinWebFont, dinBoldWebFont],
            },
        },
    },
    typography: {
        fontFamily: 'DinWeb',
        h4: {
            fontWeight: 'bold',
        },
    },
});

export default getTheme;
