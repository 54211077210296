import React from 'react';
import ApexChart from 'react-apexcharts';
import propTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { merge as _merge } from 'lodash';
import { useTheme } from '@material-ui/core/styles';

import getColoredBarSettings from './settings';

let DAYS;
const getAnnotationSettings = (
    timestamp,
    hours,
    theme,
    dayIndex,
    isLabeled,
) => ({
    x: timestamp,
    x2: timestamp + (hours === 12 ? 300000 : 700000), // first summand sets annotation position, second summand defines the width
    fillColor: theme.palette.wistaBlue,
    borderColor: theme.palette.wistaBlue,
    strokeDashArray: 0,
    opacity: hours === 12 ? 0.5 : 1,
    label: {
        offsetY: -10,
        text: hours === 12 ? `${hours}` : `${DAYS[dayIndex]}`,
        textAnchor: 'middle',
        position: 'top',
        borderWidth: 0,
        orientation: 'horizontal',
        style: {
            fontWeight: 'bold',
            color: theme.palette.wistaBlue,
            fontSize: '12px',
            background: 'transparent',
            cssClass: !isLabeled && 'hide-labels',
        },
    },
});

const isMobile = window.innerWidth < 768;

const ColoredBar = ({
    plots,
    settings,
    unit,
    isLabeled,
    isColored,
    height,
}) => {
    const theme = useTheme();
    const intl = useIntl();
    const isPlotData = plots[0]?.data;

    DAYS = [
        intl.formatMessage({ id: "sensors.charts.days.monday" }),
        intl.formatMessage({ id: "sensors.charts.days.tuesday" }),
        intl.formatMessage({ id: "sensors.charts.days.wednesday" }),
        intl.formatMessage({ id: "sensors.charts.days.thursday" }),
        intl.formatMessage({ id: "sensors.charts.days.friday" }),
        intl.formatMessage({ id: "sensors.charts.days.saturday" }),
        intl.formatMessage({ id: "sensors.charts.days.sunday" }),
    ];

    const getAggregatedValues = (plotData, dataPoint, index) => {
        const hours = new Date(dataPoint.timestamp).getHours();
        const RESOLUTION = 4;

        if (hours % RESOLUTION) {
            return null;
        }

        /**
         * calculate last four hours average
         * plotData[index - 1] = t-1
         * plotData[index - 2] = t-2
         * plotData[index - 3] = t-3
         * dataPoint.value = t-0 (now)
         */
        const dataPointValue = Math.round(
            (plotData[index - 1]?.value +
                plotData[index - 2]?.value +
                plotData[index - 3]?.value +
                dataPoint.value) /
                RESOLUTION,
        );

        return {
            x: new Date(dataPoint.timestamp).getTime(),
            y: dataPointValue || dataPoint.value, // if average could not be calculated (e.g. not enough past values), use current dataPoint.value
        };
    };

    const getSeries = () => {
        return plots.map(plot => ({
            color: plot.color,
            name: plot.title,
            type: plot.type,
            data: formatPlotData(plot.data),
        }));
    };

    const formatPlotData = plotData => {
        if (!plotData || !Array.isArray(plotData)) return [];
        return plotData
            .map((data, index) => getAggregatedValues(plotData, data, index))
            .filter(dataPoint => dataPoint);
    };

    const chartAnnotations =
        isPlotData &&
        formatPlotData(plots[0].data)
            .map(dataPoint => {
                const hours = new Date(dataPoint.x).getHours();
                const dayIndex = new Date(dataPoint.x).getUTCDay();

                if (hours !== 12 && hours !== 0) {
                    return null;
                }

                if (isMobile && hours === 12) {
                    return null;
                }

                return getAnnotationSettings(
                    dataPoint.x,
                    hours,
                    theme,
                    dayIndex,
                    isLabeled,
                );
            })
            .filter(dataPoint => dataPoint)
            .filter((dataPoint, index) => {
                if (!isMobile) return true;

                return !(index % 2);
            });

    if (chartAnnotations) {
        chartAnnotations.pop(); // last annotation is not needed (design)
    }

    const chartSettings = _merge(
        settings,
        getColoredBarSettings(
            unit,
            plots[0]?.data?.length,
            theme.palette.wistaBlue,
            chartAnnotations,
            isColored,
        ),
    );

    return (
        <>
            <ApexChart
                series={getSeries()}
                options={chartSettings}
                height={height}
                type="bar"
            />
        </>
    );
};

ColoredBar.propTypes = {
    plots: propTypes.array,
    settings: propTypes.object,
    unit: propTypes.string,
    height: propTypes.oneOfType([propTypes.number, propTypes.string]),
    isColored: propTypes.bool,
    isLabeled: propTypes.bool,
};
export default ColoredBar;
