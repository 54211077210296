import scaleBoxTypes from '../../scaleBoxTypes';

export default [
    {
        type: scaleBoxTypes.OZONE,
        minValue: 0,
        maxValue: 250,
    },
    {
        type: scaleBoxTypes.PARTICULATE_MATTER,
        minValue: 0,
        maxValue: 108,
    },
    {
        type: scaleBoxTypes.NITROGEN_DIOXIDE,
        minValue: 0,
        maxValue: 530,
    },
    {
        type: scaleBoxTypes.CARBON_MONOXIDE,
        minValue: 0,
        maxValue: 31,
    },
];
