import React from 'react';
import propTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import { dimensions as widgetDimensions } from '../widget';
import Widget from '../widget/Widget';
import UpdatingData from '../updatingData/UpdatingData';
import Scale from './components/scale/Scale';
import styles from './styles';

const ScaleBox = ({
    title,
    value,
    unit,
    size = 'small',
    scaleBoxType,
    noData,
}) => {
    const classes = styles();
    const dimensions = widgetDimensions[size];

    return (
        <Widget
            title={<FormattedMessage id={title} />}
            {...dimensions}
            titleVariant="h5"
        >
            <Scale
                scaleBoxType={scaleBoxType}
                value={value}
            />
            <div className={classes.value}>
                {value || value === 0 ? (
                    <>
                        <Typography variant="h3">{value}</Typography>
                        <Typography>{unit}</Typography>
                    </>
                ) : (
                    <Typography variant="h3">
                        <FormattedMessage id="component.noValue" />
                    </Typography>
                )}
            </div>
            {noData && <UpdatingData />}
        </Widget>
    );
};

ScaleBox.propTypes = {
    title: propTypes.string,
    value: propTypes.oneOfType([propTypes.number, propTypes.string]),
    unit: propTypes.string,
    size: propTypes.string,
    scaleBoxType: propTypes.string,
    noData: propTypes.bool,
};

export default ScaleBox;
