import React, { useEffect, useState } from 'react';

import ueApiService from '../services/ueApi';

import { REACT_APP_COM2M_ENVIRONMENTAL_SENSOR } from '../resources/env';

const withSensorData = WrappedComponent => {
    return function SensorData() {
        // fetch interval for when data can be fetched successfully
        const FETCH_DATA_INTERVAL = 600000;
        // fetch interval for when data could not be fetched
        const ERROR_RETRY_INTERVAL = 30000;
        const assetId = REACT_APP_COM2M_ENVIRONMENTAL_SENSOR;
        const [sensorData, setSensorData] = useState({
            location: null,
            plots: null,
            aqi: null,
        });

        /**
         * @isCachedData is true, when no data could be fetched, but sensorData is stored in sessionStorage
         * is set to false, when data has sucessfully been fetched
         */
        const [isCachedData, setIsCachedData] = useState(false);
        /**
         * @cachedSensorData is stored in sessionStorage whenever data has been fetched successfully
         * renders the page with appropriate data, even if an error occurs
         */
        const cachedSensorData = window.sessionStorage.getItem('sensorData');

        /**
         * @error is true, when no data could be fetched
         * handles interval time
         * displays update message on components, when no cachedSensorData is stored
         */
        const [error, setError] = useState(false);

        useEffect(() => {
            const fetchData = async () => {
                // fetch plots
                const { response: plots } = await ueApiService.getAqiSeries(
                    assetId,
                );

                // fetch aqi
                const { response: aqi } = await ueApiService.getAQI(assetId);

                // fetch location
                const { response: location } = await ueApiService.getAsset(
                    assetId,
                );

                // statement order matters
                if (aqi && location && plots) {
                    const data = {
                        plots,
                        location,
                        aqi,
                    };
                    // disable isCachedData to render the page with updated data
                    setIsCachedData(false);
                    setError(false);
                    setSensorData(prev => ({
                        ...prev,
                        ...data,
                    }));
                    window.sessionStorage.setItem(
                        'sensorData',
                        JSON.stringify(data),
                    );
                } else if (cachedSensorData) {
                    /**
                     * try to parse cachedSensorData and use it to render the page properly
                     * catch it, if cachedSensorData could not be parsed and instead, set error to true to activate error retry interval and to display update message
                     */
                    try {
                        const parsedSensorData = JSON.parse(cachedSensorData);
                        // set isCachedData to true, to render the page with cachedSensorData
                        setIsCachedData(true);
                        // set error to true, to activate the error retry interval
                        setError(true);
                        setSensorData(prev => ({
                            ...prev,
                            ...parsedSensorData,
                        }));
                    } catch {
                        setError(true);
                    }
                } else {
                    setIsCachedData(false);
                    setError(true);
                }
            };
            fetchData();
            let fetchInterval;

            if (error) {
                fetchInterval = setInterval(fetchData, ERROR_RETRY_INTERVAL);
            } else {
                fetchInterval = setInterval(fetchData, FETCH_DATA_INTERVAL);
            }

            return () => {
                clearInterval(fetchInterval);
            };
        }, [assetId, cachedSensorData, error]);

        /**
         * if no error, no cached data and no response exist return null
         * happens to be the first render of the page and react wants to have a return value for components
         */
        if (
            !error &&
            !isCachedData &&
            !(sensorData.aqi && sensorData.location && sensorData.plots)
        )
            return null;

        // return order matters
        if (
            isCachedData ||
            (sensorData.aqi && sensorData.location && sensorData.plots)
        )
            return <WrappedComponent sensorData={sensorData} error={false} />;

        return (
            <WrappedComponent
                sensorData={{
                    location: null,
                    plots: null,
                    aqi: null,
                }}
                error={true}
            />
        );
    };
};

export default withSensorData;
