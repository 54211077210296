import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
    value: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        minHeight: 30,
    },
});
