import React from 'react';
import { Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import styles from './styles';

const UpdatingData = () => {
    const classes = styles();
    return (
        <div className={classes.root}>
            <Typography variant="h6">
                <FormattedMessage id="wista.updating_data.text" />
            </Typography>
        </div>
    );
};

export default UpdatingData;
