import { Typography } from '@material-ui/core';
import propTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import aqiDefinitions from '../airQualityBox/aqiDefinitions';

import styles from './styles';

const AirQualityIndexSimple = ({ value }) => {
    const classes = styles();
    const gradeAsTranslationKey = aqiDefinitions.find(
        definitions => definitions.value === value,
    );

    return (
        <div className={classes.root}>
            <Typography className={classes.currentAqiText}>
                <FormattedMessage id="wista.aqi_simple.current" /> <br />{' '}
                <FormattedMessage id="wista.aqi_simple.aqi" />
            </Typography>
            <Typography className={classes.value}>
                {value && gradeAsTranslationKey ? (
                    <FormattedMessage
                        id={gradeAsTranslationKey.translationKey}
                    />
                ) : (
                    <FormattedMessage id="ueApp.kpibox.novalue" />
                )}
            </Typography>
        </div>
    );
};

AirQualityIndexSimple.propTypes = {
    value: propTypes.string,
    noData: propTypes.bool,
};

export default AirQualityIndexSimple;
