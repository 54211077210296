import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
    root: {
        border: `2px solid ${theme.palette.wistaBlue}`,
        padding: '4px 0',
        margin: '0 -10px 8px',
        position: 'relative',
    },
    scale: {
        display: 'block',
        height: 30,
        width: '100%',
        background:
            'linear-gradient(to right, #A6FCE3, #D2F3AA, #FCF0A5, #F7AC98, #F6848B)',
    },
    valueMarker: {
        position: 'absolute',
        transform: 'scale(1.5, 3)',
    },
    valueMarkerBottom: {
        bottom: -8,
    },
    valueMarkerTop: {
        top: -8,
    },
    valueBox: {
        background: theme.palette.wistaBlue,
        borderRadius: '4px',
        color: '#ffffff',
        padding: '4px 8px',
        display: 'inline-block',
        position: 'absolute',
        top: 40,
        textTransform: 'uppercase',
        '& p': {
            fontSize: '1.6rem',
        },
    },
}));
