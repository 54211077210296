import axios from 'axios';

import { REACT_APP_MOCKS } from '../../../../resources/env';

const MOCKS_CONFIG = {
    baseURL: '/__mocks__',
};

const UE_API_CONFIG = {
    headers: {},
    validateStatus: status => status < 500,
};

const ueApiInstance = axios.create(REACT_APP_MOCKS ? MOCKS_CONFIG : UE_API_CONFIG);

export default ueApiInstance;
