import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
    scaleComponent: {
        border: `2px solid ${theme.palette.wistaBlue}`,
        padding: 4,
        display: 'flex',
        width: '100%',
        height: '40px',
        boxSizing: 'border-box',
        textAlign: 'center',
        flexDirection: 'reverse',
    }
}));
